<template>
    <div class="feature">
        <h1 class="d-none d-xl-block">
            {{ feature.title }}
        </h1>
        <div class="row align-items-center">
            <div class="col">
                <div class="feature-info" :class="{ 'open' : feature.isExpanded }">
                    <h1 class="d-flex d-xl-none">
                        {{ feature.title }}
                    </h1>
                    <div v-if="feature.subtitle" class="subtitle">
                        {{ feature.subtitle }}
                    </div>
                    <ul>
                        <template v-for="(descriptionBullet, index) in feature.descriptionBullets">
                            <li :key="index" :class="{ 'hide-on-mobile' : descriptionBullet.hideOnMobile }">
                                <strong v-if="descriptionBullet.title">{{ descriptionBullet.title }}</strong>
                                {{ descriptionBullet.bullet }}
                                <a v-if="descriptionBullet.link" :href="descriptionBullet.link.href" target="_blank">{{ descriptionBullet.link.text }}</a>
                            </li>
                        </template>
                    </ul>
                    <div v-if="feature.afterText" class="subtitle">
                        {{ feature.afterText }}
                    </div>
                    <img
                        :src="require(`@assets/img/founders-landing/more-features/${feature.relativePathImages.mobile.x1}`)"
                        :srcset="`${require(`@assets/img/founders-landing/more-features/${feature.relativePathImages.mobile.x1}`)} 1x, ${require(`@assets/img/founders-landing/more-features/${feature.relativePathImages.mobile.x2}`)} 2x`"
                        class="d-flex d-xl-none"
                        :alt="feature.title"
                    >
                </div>
            </div>
            <div class="col-auto pb-4 d-none d-xl-flex">
                <img
                    :src="require(`@assets/img/founders-landing/more-features/${feature.relativePathImages.web.x1}`)"
                    :srcset="`${require(`@assets/img/founders-landing/more-features/${feature.relativePathImages.web.x1}`)} 1x, ${require(`@assets/img/founders-landing/more-features/${feature.relativePathImages.web.x2}`)} 2x`"
                    class="img-fluid"
                    :alt="feature.title"
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Feature",
    props: {
        feature: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.feature {
    margin-bottom: 200px;
    pointer-events: none;

    h1 {
        @extend .h0;
        text-align: center;
        /* line-height: 100px; */
        margin-bottom: 50px;
        color: $primary-color;

        @media(max-width: $xl) {
            text-align: center;
        }
    }

    .subtitle {
        z-index: 2;
        position: relative;
    }

    ul {
        list-style: none;

        li {
            position: relative;
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 20px;

            &::before {
                content: "";
                position: absolute;
                top: 10px;
                width: 22px;
                height: 22px;
                background-image: url("~@assets/img/founders-landing/more-features/bullet.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                margin-left: -40px;
                border-radius: 100%;
            }

            &:last-child {
                margin-bottom: 0;
            }

            a {
                pointer-events: initial;
                color: $primary-color;
            }
        }
    }

    img {
        border-radius: 12px;
    }

    @media(max-width: $xl) {
        margin-bottom: 0;
        pointer-events: initial;

        .row {
            > .col {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .feature-info {
            max-height: 90px;
            overflow: hidden;
            position: relative;
            transition: max-height .5s;

            h1 {
                position: relative;
                margin-bottom: 0;
                margin-left: 0 !important;
                font-size: 24px;
                line-height: 27px;
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                z-index: 2;
            }

            ul {
                position: relative;
                z-index: 2;
                padding-right: 30px;
                padding-left: 60px;

                li {
                    font-size: 16px;
                    line-height: 27px;
                    margin-bottom: 30px;

                    &::before {
                        top: 3px;
                    }

                    &:last-child {
                        margin-bottom: 30px;
                    }

                    &.hide-on-mobile {
                        display: none;
                    }
                }
            }

            img {
                position: absolute;
                transform: translateY(-50%);
                transition: transform .5s;
                top: 0;
                margin-left: 0 !important;
                border-radius: 0;
                z-index: 1;
                width: 100%;
            }

            &.open {
                min-height: 550px;
                max-height: 1600px;

                img {
                    transform: translateY(30%) scale(2);
                }
            }
        }
    }

    .subtitle {
        font-size: 18px;
        line-height: 38px;
        margin-bottom: 40px;
        margin-left: 40px;
    }
}
</style>
