<template>
    <div class="more-features">
        <template v-for="(feature, index) in moreFeatures">
            <feature-component
                :id="`more-feature-${index}`"
                :key="index"
                :feature="feature"
                @click.native="toggleExpandedMoreFeatures(index)"
            />
        </template>
    </div>
</template>

<script>
export default {
    name: "MoreFeatures",
    components: {
        FeatureComponent: () => import(/* webpackChunkName: "feature-component" */ "./feature")
    },
    data() {
        return {
            isMoreFeaturesExpanded: false,
            moreFeatures: [
                {
                    title: "Memos: A new way of gaining and sharing knowledge",
                    isExpanded: false,
                    descriptionBullets: [
                        {
                            hideOnMobile: false,
                            bullet: "If something’s worth knowing, it’s worth remembering – and with as little effort as possible."
                        },
                        {
                            hideOnMobile: false,
                            bullet: "A Memo is a note that makes any subject instantly understandable through its simple, 10 bullet point structure."
                        },
                        {
                            hideOnMobile: false,
                            bullet: "It’s bite-sized inspiration that you can easily retain with no fluff and no wasted time."
                        },
                        {
                            hideOnMobile: false,
                            bullet: "Memo’d is designed to streamline your expertise so that your insights can be easily learned and shared by anyone."
                        }
                    ],
                    relativePathImages: {
                        web: {
                            x1: "sharing-knowledge.jpg",
                            x2: "sharing-knowledge@2x.jpg"
                        },
                        mobile: {
                            x1: "sharing-knowledge-mobile.jpg",
                            x2: "sharing-knowledge-mobile@2x.jpg"
                        }
                    }
                },
                {
                    title: "Founding Creators Program: Why you?",
                    isExpanded: false,
                    descriptionBullets: [
                        {
                            hideOnMobile: false,
                            bullet: "We've reached out to you for a reason: your unique insight and know-how on specific subjects"
                        },
                        {
                            hideOnMobile: false,
                            bullet: "Memo’d is designed to help you organize and publish your ideas, and reach a global audience"
                        },
                        {
                            hideOnMobile: false,
                            bullet: "As an early invited creator you will be given special opportunities to gain followers and generate revenue from your Memos"
                        },
                        {
                            hideOnMobile: false,
                            bullet: "Organizing your knowledge into 10-point Memos helps you retain valuable information while also inspiring and benefiting others"
                        },
                        {
                            hideOnMobile: false,
                            bullet: "You can even repurpose your existing content into Memos. We’ll give you the tools to get you started, and guide you every step of the way"
                        },
                        {
                            hideOnMobile: false,
                            bullet: "Research and “mine” any form of content and bring it to a wider audience in a compelling way"
                        },
                        {
                            hideOnMobile: false,
                            bullet: "Don’t keep your insights hidden. The world wants to know. Memo’d is the new place to share them"
                        },
                        {
                            hideOnMobile: false,
                            bullet: "Know someone who would be a perfect fit for the Founding Creators Program? Great! Just send your recommendation to hello@memod.com"
                        }
                    ],
                    relativePathImages: {
                        web: {
                            x1: "why-you.png",
                            x2: "why-you@2x.png"
                        },
                        mobile: {
                            x1: "why-you-mobile.jpg",
                            x2: "why-you-mobile@2x.jpg"
                        }
                    }
                },
                {
                    title: "Program Perks",
                    isExpanded: false,
                    descriptionBullets: [
                        {
                            hideOnMobile: false,
                            title: "Recognition:",
                            bullet: "Show others you're a trusted source of wisdom within the platform with a certified Memo’d Expert icon."
                        },
                        {
                            hideOnMobile: false,
                            title: "Updates:",
                            bullet: "Stay in the know on the latest features and functions with exclusive product updates directly from the Memo'd team."
                        },
                        {
                            hideOnMobile: false,
                            title: "Revenue:",
                            bullet: "Memo’d users are encouraged to tip creators, so you will receive tips from readers of your Memos.  A small portion of all tips goes towards keeping the Memo’d platform free, but participants in our Founding Creators Program get an exclusive lower rate."
                        },
                        {
                            hideOnMobile: false,
                            title: "Expert guidance:",
                            bullet: "Memo’d staff will help you craft content that reaches the wide audience it deserves. We'll even help you build a loyal follower base with shared interests."
                        }
                    ],
                    relativePathImages: {
                        web: {
                            x1: "program-perks.jpg",
                            x2: "program-perks@2x.jpg"
                        },
                        mobile: {
                            x1: "program-perks-mobile.jpg",
                            x2: "program-perks-mobile@2x.jpg"
                        }
                    }
                }
            ]
        };
    },
    methods: {
        toggleExpandedMoreFeatures(index) {
            this.moreFeatures[index].isExpanded = !this.moreFeatures[index].isExpanded;

            if (this.moreFeatures[index].isExpanded) {
                const id = `more-feature-${index}`;
                const navBar = document.querySelector(".nav-bar");
                const featureComponent = document.getElementById(id);
                const yOffset = - navBar.offsetHeight;
                const y = featureComponent.getBoundingClientRect().top + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: "smooth" });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.more-features {
    /deep/ .feature {
        &:nth-child(even) {
            .row {
                flex-direction: row-reverse;

                h1 {
                    margin-left: 40px;
                }

                ul {
                    margin-left: 0;
                }

                img {
                    margin-left: 0;
                    margin-right: 40px;
                }
            }
        }
    }
}
</style>
